import React, { useEffect, useState } from "react"
import { animated } from "react-spring"
import styled from "styled-components"
import video from "../images/intro.mp4"

const Video = styled.video`
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  cursor: pointer;
  margin-bottom: -5px;
  z-index: 10;
  position: relative;
`

const Hint = styled(animated.span)`
  z-index: 11;
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  font-size: 8.5vw;
`

const Intro = () => {
  const [hint, setHint] = useState(false)

  // show "click to enter" after 5 seconds
  useEffect(() => {
    setTimeout(() => {
      setHint(true)
    }, 5000)
  }, [])

  return (
    <>
      {hint && <Hint>Click to enter</Hint>}

      <Video
        muted
        autoPlay
        playsInline
        loop
        autoBuffer
        preload="auto"
        poster="poster.jpg"
      >
        <source src={video} type="video/mp4" />
        Your browser does not support HTML video :( Click to enter!
      </Video>
    </>
  )
}

export default Intro

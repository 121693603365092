import styled from "styled-components"
import { bpMobile } from "../../utils/vars"

export const Placing = styled.div`
  box-sizing: border-box;
  padding-top: 4vw;
  margin-bottom: -20vw;

  /* GRID */
  display: grid;
  grid-template-rows: repeat(93, 7vw);
  grid-template-columns: repeat(20, 1fr);
  padding: 0 5%;

  img,
  video {
    height: auto;
    width: 100%;
  }

  /* YOUTUBE EMBEDS */
  #vid-1 {
    grid-row: 6;
    grid-column: 3 / span 7;
    z-index: 2;
  }
  #vid-2 {
    grid-row: 12;
    grid-column: 11 / span 7;
    z-index: 1;
  }
  #vid-3 {
    grid-row: 21;
    grid-column: 3 / span 7;
  }
  #vid-4 {
    grid-row: 29;
    grid-column: 9 / span 7;
    z-index: 1;
  }
  #vid-5 {
    grid-row: 36;
    grid-column: 4 / span 7;
  }
  #vid-6 {
    grid-row: 49;
    grid-column: 12 / span 7;
  }
  #vid-7 {
    grid-row: 58;
    grid-column: 5 / span 7;
  }
  #vid-8 {
    grid-row: 61;
    grid-column: 11 / span 9;
    z-index: 1;
  }
  #vid-9 {
    grid-row: 65;
    grid-column: 4 / span 7;
  }
  #vid-10 {
    grid-row: 73;
    grid-column: 6 / span 7;
  }
  #vid-11 {
    grid-row: 81;
    grid-column: 12 / span 7;
  }
  #vid-12 {
    grid-row: 85;
    grid-column: 12 / span 7;
    z-index: 1;
  }

  /* IMAGES  */
  .img-1 {
    grid-row: 2;
    grid-column: 4 / span 6;
    z-index: 1;
  }
  .img-2 {
    grid-row: 4;
    grid-column: 9 / span 9;
  }
  .img-3 {
    grid-row: 9;
    grid-column: 13 / span 6;
  }
  .img-4 {
    grid-row: 10;
    grid-column: 4 / span 8;
  }
  .img-5 {
    grid-row: 16;
    grid-column: 3 / span 7;
    z-index: 2;
  }
  .img-6 {
    grid-row: 19;
    grid-column: 5 / span 5;
    z-index: 2;
  }
  .img-7 {
    grid-row: 17;
    grid-column: 9 / span 6;
    z-index: 1;
  }
  .img-8 {
    grid-row: 16;
    grid-column: 14 / span 5;
    z-index: 2;
  }
  .img-9 {
    grid-row: 19;
    grid-column: 16 / span 3;
  }
  .img-10 {
    grid-row: 25;
    grid-column: 5 / span 6;
    z-index: 1;
  }
  .img-11 {
    grid-row: 24;
    grid-column: 10 / span 9;
  }
  .img-12 {
    grid-row: 28;
    grid-column: 3 / span 5;
  }
  .img-13 {
    grid-row: 31;
    grid-column: 3 / span 9;
  }
  .img-14 {
    grid-row: 32;
    grid-column: 13 / span 6;
  }
  .img-15 {
    grid-row: 39;
    grid-column: 3 / span 6;
  }
  .img-16 {
    grid-row: 39;
    grid-column: 10 / span 9;
  }
  .img-17 {
    grid-row: 43;
    grid-column: 4 / span 8;
  }
  .img-18 {
    grid-row: 44;
    grid-column: 9 / span 5;
    z-index: 1;
  }
  .img-19 {
    grid-row: 43;
    grid-column: 14 / span 4;
  }
  .img-20 {
    grid-row: 45;
    grid-column: 13 / span 5;
  }
  .img-21 {
    grid-row: 47;
    grid-column: 3 / span 8;
  }
  .img-22 {
    grid-row: 52;
    grid-column: 7 / span 10;
  }
  .img-23 {
    grid-row: 53;
    grid-column: 3 / span 5;
    z-index: 1;
  }
  .img-24 {
    grid-row: 56;
    grid-column: 13 / span 6;
  }
  .img-25 {
    grid-row: 57;
    grid-column: 11 / span 3;
    z-index: 1;
  }
  .img-26 {
    grid-row: 60;
    grid-column: 4 / span 6;
    z-index: 1;
  }
  .img-27 {
    grid-row: 63;
    grid-column: 3 / span 4;
    z-index: 1;
  }
  .img-28 {
    grid-row: 65;
    grid-column: 12 / span 5;
  }

  .img-29 {
    grid-row: 68;
    grid-column: 3 / span 5;
    z-index: 1;
  }
  .img-30 {
    grid-row: 69;
    grid-column: 7 / span 8;
  }
  .img-31 {
    grid-row: 68;
    grid-column: 15 / span 4;
    z-index: 1;
  }
  .img-32 {
    grid-row: 72;
    grid-column: 3 / span 3;
    z-index: 1;
  }
  .img-33 {
    grid-row: 72;
    grid-column: 12 / span 5;
    z-index: 1;
  }
  .img-34 {
    grid-row: 77;
    grid-column: 3 / span 7;
  }
  .img-35 {
    grid-row: 76;
    grid-column: 8 / span 6;
    z-index: 1;
  }
  .img-36 {
    grid-row: 74;
    grid-column: 15 / span 3;
    z-index: 2;
  }
  .img-37 {
    grid-row: 81;
    grid-column: 3 / span 5;
  }
  .img-38 {
    grid-row: 80;
    grid-column: 7 / span 4;
    z-index: 1;
  }
  .img-39 {
    grid-row: 81;
    grid-column: 11 / span 5;
    z-index: 1;
  }
  .img-40 {
    grid-row: 86;
    grid-column: 3 / span 4;
  }
  .img-41 {
    grid-row: 84;
    grid-column: 9 / span 4;
    z-index: 2;
  }
  .img-42 {
    grid-row: 86;
    grid-column: 8 / span 9;
  }
  .img-43 {
    grid-row: 90;
    grid-column: 5 / span 10;
  }

  @media only screen and (max-width: ${bpMobile}) {
    margin: 4rem 1rem;
    padding: 0;

    display: flex;
    flex-direction: column;

    .img-wrapper {
      margin-bottom: 3rem;
    }

    .iframe-wrapper {
      display: none;
    }
  }
`

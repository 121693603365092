import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    border: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const YoutubeEmbed = ({ wrapperId, youtubeId }) => {
  const url = `https://www.youtube.com/embed/${youtubeId}?controls=0&amp;showinfo=0&amp;loop=1&amp;autoplay=1&amp;mute=1&amp;playlist=${youtubeId}&vq=hd720`
  return (
    <Wrapper id={wrapperId} className="iframe-wrapper">
      <iframe
        title="Video"
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        alt="film"
      ></iframe>
    </Wrapper>
  )
}

export default YoutubeEmbed

YoutubeEmbed.propTypes = {
  wrapperId: PropTypes.string.isRequired,
  youtubeId: PropTypes.string.isRequired,
}

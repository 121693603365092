import React from "react"
import Img from "gatsby-image"
import { Placing } from "./Placing"
import YoutubeEmbed from "./YoutubeEmbed"
import { Parallax } from "react-scroll-parallax"

const Grid = props => (
  <Placing>
    {props.images.map((image, i) => (
      <Parallax
        y={[-15, 0]}
        key={image.node.childImageSharp.id}
        className={`img-wrapper img-${i + 1}`}
        disabled={window.innerWidth < 700 ? true : false}
      >
        <Img fluid={image.node.childImageSharp.fluid} draggable={false} />
      </Parallax>
    ))}
    <YoutubeEmbed wrapperId="vid-1" youtubeId="m88Pq2TR9c8" />
    <YoutubeEmbed wrapperId="vid-2" youtubeId="ob_0IxUPw5w" />
    <YoutubeEmbed wrapperId="vid-3" youtubeId="4QZyplmO30c" />
    <YoutubeEmbed wrapperId="vid-4" youtubeId="HwBU8Pb6zhw" />
    <YoutubeEmbed wrapperId="vid-5" youtubeId="sHPIZaHa9ag" />
    <YoutubeEmbed wrapperId="vid-6" youtubeId="LM1f1B-etr0" />
    <YoutubeEmbed wrapperId="vid-7" youtubeId="q6RrBbAiVDE" />
    <YoutubeEmbed wrapperId="vid-8" youtubeId="PlrsDHinnjE" />
    <YoutubeEmbed wrapperId="vid-9" youtubeId="tJYd457G1FA" />
    <YoutubeEmbed wrapperId="vid-10" youtubeId="tU6mjMaj2ys" />
    <YoutubeEmbed wrapperId="vid-11" youtubeId="e6aSBg1hurc" />
    <YoutubeEmbed wrapperId="vid-12" youtubeId="JYxCJvxu0BU" />
  </Placing>
)

export default Grid

import React, { useEffect, useState } from "react"
import Grid from "../components/Gallery/Grid"
import Nav from "../components/Nav"
import Footer from "../components/Scenery/Footer"
import Stars from "../components/Scenery/Stars"
import IntroVideo from "../components/Intro"
import Layout from "../layouts/default"

const IndexPage = ({ data }) => {
  const [intro, setIntro] = useState(true)
  const images = data.allFile.edges
  const footerImage = data.village.childImageSharp
  const { fluid } = data.stars.childImageSharp

  useEffect(() => {
    const docHeight = document.body.clientHeight
    if (window.innerWidth >= 600) {
      window.scrollTo(0, docHeight)
    }
  })

  return (
    <Layout>
      {!intro ? (
        <>
          <Stars src={fluid} />
          <Nav />
          <Grid images={images} />
          <Footer image={footerImage} />
        </>
      ) : (
        <div onClick={() => setIntro(false)} onKeyDown={() => setIntro(false)}>
          <IntroVideo />
        </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(
      filter: { relativeDirectory: { eq: "work" } }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            id
            fluid(maxWidth: 600, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    village: file(name: { eq: "village" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    stars: file(name: { eq: "stars" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default IndexPage

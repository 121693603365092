import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  z-index: 7;
  top: 0;
  left: 0;
`

const Stars = ({ src }) => (
  <Wrapper>
    <Img fluid={src} draggeable={false} />
  </Wrapper>
)

export default Stars

import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Wrapper = styled.div`
  z-index: 5;
  width: 100%;
  pointer-events: none;
`

const Village = styled(Img)`
  width: 100%;
`

const Copyright = styled.div`
  width: 100vw;
  display: flex;
  justify-content: space-around;
  font-size: 1.25rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-style: italic;

  div {
    padding: 2rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 0.9rem;

    div {
      padding: 3rem 0;
    }
  }
`

const Footer = props => {
  const image = props.image.fluid
  return (
    <Wrapper>
      <Village
        fadeIn={false}
        fluid={image}
        draggeable={false}
        loading="eager"
      />
      <Copyright>
        <div>All Visuals &copy; {new Date().getFullYear()} Luc Karsten</div>
      </Copyright>
    </Wrapper>
  )
}

export default Footer
